export const DEFAULT_IMAGE_ICON_SIZE = 33;

export const THUMBNAIL_WIDTH = 60;

export const NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE = 0;

export const MAX_ITEM_OPTION_LENGTH = 500;

export const SPECS = {
  AddSlotToCheckout: 'specs.stores.AddSlotToCheckout',
  DontRedirectToSiteOnFailedFetch: 'specs.stores.DontRedirectToSiteOnFailedFetch',
  CheckoutExcludeGoogleAutoComplete: 'specs.stores.CheckoutExcludeGoogleAutoComplete',
  ShouldFetchCustomSettingsAndCustomizeCheckoutLayout:
    'specs.stores.ShouldFetchCustomSettingsAndCustomizeCheckoutLayout',
  ShouldUsePanorama: 'specs.stores.ShouldUsePanorama',
  ShouldSplitBillingInfoPrefill: 'specs.stores.ShouldSplitBillingInfoPrefill',
  SlotSpacingAndDividers: 'specs.stores.SlotSpacingAndDividers',
  HideBillingFormForPayPalAndManualNotBrazil: 'specs.stores.HideBillingFormForPayPalAndManualNotBrazil',
  EnablePlaceOrderButtonForExistingNoAddressCustomer: 'specs.stores.EnablePlaceOrderButtonForExistingNoAddressCustomer',
  WithCheckoutHackForReact18Tests: 'specs.stores.WithCheckoutHackForReact18Tests',
  GiftCartSeviceDownCheckout: 'specs.stores.GiftCartSeviceDownCheckout',
  ShowTaxBreakdownInCheckout: 'specs.stores.ShowTaxBreakdownInCheckout',
  ShowErrorForMissingZipcode: 'specs.stores.ShowErrorForMissingZipcode',
  UseUndefinedAsDefaultBillingAddressInCheckout: 'specs.stores.UseUndefinedAsDefaultBillingAddressInCheckout',
  UseSettingsOverridesForPaylinks: 'specs.stores.UseSettingsOverridesForPaylinks',
  UseGenericEmailLabel: 'specs.stores.UseGenericEmailLabel',
  ShouldAlwaysCollapseBottomSummary: 'specs.stores.ShouldAlwaysCollapseBottomSummary',
  MoveSlotAboveCheckoutButton: 'specs.stores.MoveSlotAboveCheckoutButton',
  ShowExpressCheckoutButtonsOnInitialStep: 'specs.stores.ShowExpressCheckoutButtonsOnInitialStep',
  UseCheckoutContentOverride: 'specs.stores.UseCheckoutContentOverride',
  SupportCardTokenizationOnCartAndCheckout: 'specs.stores.SupportCardTokenizationOnCartAndCheckout',
  SupportDelayedCaptureOnCheckoutAndExpressButtons: 'specs.stores.SupportDelayedCaptureOnCheckoutAndExpressButtons',
  addCustomPolicyToCheckboxesSection: 'specs.stores.addCustomPolicyToCheckboxesSection',
  FixSetAsDefaultAddress: 'specs.stores.FixSetAsDefaultAddress',
  ShouldWaitForMemberServiceInit: 'specs.stores.ShouldWaitForMemberServiceInit',
  UseCheckoutHeaderOnCheckout: 'specs.stores.UseCheckoutHeaderOnCheckout',
  ShowVatIdOnBillingForIsrael: 'specs.stores.ShowVatIdOnBillingForIsrael',
};
